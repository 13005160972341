function SET_PRODUCT(state, payload) {
    if (payload) {
        state.product = payload;
    } else {
        console.error('Получен пустой объект для SET_PRODUCT!');
    }
};

function CLEAR_PRODUCT(state) {
    state.product = null;
}

function SET_PRODUCT_ANALOGS(state, payload) {
    state.productAnalogs = payload;
};

function SET_PRODUCT_FORM_FACTOR(state, payload) {
    state.productFormFactor = payload;
};

function SET_PRODUCT_ADDITIONAL_SALES(state, payload) {
    state.productAdditionalSales = payload;
};

function SET_CURRENT_PRODUCT_ANALOGS(state, payload) {
    state.currentProductAnalogs = payload;
};

export {
    SET_PRODUCT,
    CLEAR_PRODUCT,
    SET_PRODUCT_ANALOGS,
    SET_PRODUCT_FORM_FACTOR,
    SET_PRODUCT_ADDITIONAL_SALES,
    SET_CURRENT_PRODUCT_ANALOGS,
};
