import {request} from 'src/boot/axios';

async function GET_PRODUCT_BY_ID(context, { id }) {
    return await request('get', `products/${id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    });
};

async function GET_PREVIEWS_BY_CATEGORY(context, [category_id, query]) {
    const result = await request('get', `previews/by_category/${category_id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        query,
    });

    return result;
};

async function GET_PREVIEW_BY_ID(context, { id }) {
    return await request('get', `previews/${id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    });
};

async function GET_PRODUCT_FORM_FACTOR(context) {
    let query = {
        brand: context.state.product.name,
    };

    let category = context.state.product.category_path.slice();

    let previews = (await context.dispatch('GET_PREVIEWS_BY_CATEGORY', [
        category.pop().id,
        query,
    ])).result;

    for (let i = 0; i < previews.length; i++) {
        if (previews[i].id === context.state.product.id) {
            previews.splice(i, 1);
        }
    }

    context.commit('SET_PRODUCT_FORM_FACTOR', previews);
};

async function GET_PRODUCT_ADDITIONAL_SALES(context, { id }) {
    const result = await request('get', `previews/additional-sales/${id}`, {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    });

    context.commit('SET_PRODUCT_ADDITIONAL_SALES', result);
};

async function GET_PRODUCT_BY_SLUG(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    let result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': data.city_id || context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/detail/${data.id}`);
    if (result) {
        context.commit('SET_PRODUCT', result);
    }
    return result;
};

async function GET_PRODUCT_ANALOGS(context, data) {
    await context.dispatch('user/CHECK_TOKEN', null, { root: true });

    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
        token: context.rootState.user.token,
        query: {
            pharmacy: data.pharmacy || null,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/search/products/basket-analogs/${data.slug}`);

    context.commit('SET_PRODUCT_ANALOGS', result);

    return result;
};

async function GET_CAPTCHA(context, data) {
    const result = await request('get', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
        },
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/reviews/review/captcha`);

    return `data:image/jpeg;base64,${result}`;
}

async function SET_PRODUCT_REVIEW(context, data) {
    const result = await request('post', '', {
        headers: {
            'x-channel': 'web',
            'x-city-id': context.rootState.user.city_id,
            'x-user-id': context.rootState.user.id,
            'x-captcha-id': data.captchaId,
        },
        data: data.info,
    }, `https://api.${process.env.APP_SERVICE_HOSTNAME}/api/v1/reviews/product-review`);

    return result;
}

export {
    GET_PREVIEW_BY_ID,
    GET_PRODUCT_BY_ID,
    GET_PRODUCT_ANALOGS,
    GET_PRODUCT_FORM_FACTOR,
    GET_PREVIEWS_BY_CATEGORY,
    GET_PRODUCT_ADDITIONAL_SALES,
    GET_PRODUCT_BY_SLUG,
    SET_PRODUCT_REVIEW,
    GET_CAPTCHA,
};
